<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4" style="min-width=300px; max-width=300px; background-color:#3c2468; border:none" 
            text-color="white">
            <CCardBody>
              <CForm>
                <h1 style="color:white; font-size:26px;">Portal Admin vapp</h1>
                <p class="text-muted" style="color:white">Reportes Vapp</p>
                <CInput
                  placeholder="Usuario"
                  autocomplete="Usuario"
                  v-model="usuario.username"
                  @keyup.enter.native="iniciarSesion"
                ></CInput>
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="contraseña"
                  v-model="usuario.pass"
                  @keyup.enter.native="iniciarSesion"></CInput>
                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert style="max-width:250px" closeButton v-show="showMensaje" :color="tipoMensaje">{{mensajeLogin}}</CAlert>
                  </CCol>
                </CRow>
                <CRow v-show="!showMensajeCambioPass">
                  <CCol col="6" class="text-rigth"></CCol>
                  <CCol col="6" class="text-rigth">
                    <CButton style="background-color:#0b7689;color:white;" class="px-4" @click="iniciarSesion">Ingresar</CButton>
                  </CCol>
                  <!--<CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">Forgot password?</CButton>
                    <CButton color="link" class="d-md-none">Register now!</CButton>
                  </CCol>-->
                </CRow>
                <CRow class="mt-2" v-show="showMensajeCambioPass">
                  <CCol sm="12" md="12" lg="12">
                    <CRow>
                      <CAlert :color="tipoMensaje" style="max-width:250px">{{msjCambiarPass}}</CAlert>
                    </CRow>
                    <CRow v-show="!enviandoSolicitud">
                      <CCol sm="8" md="8" lg="6">
                        <CButton style="background-color:#0b7689;color:white;" @click="loguearUser">Luego</CButton>
                      </CCol>
                      <CCol sm="4" md="4" lg="6">
                        <CButton style="background-color:#0b7689;color:white;" @click="cambiarContraseña()">Si!</CButton>
                      </CCol>
                    </CRow>
                    <CRow v-show="enviandoSolicitud">
                      <CCol sm="12" md="12" lg="12">{{msjSolicitandoCambio}}</CCol>
                    </CRow>
                  </CCol>
                </CRow>
                
              </CForm>
            </CCardBody>
          </CCard>

          <!--<CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-sm-down-none"
            body-wrapper
          >
            <h1>Sistema de Reportes VAPP</h1>
            <p>Para ingresar utilice su usuario y contraseña otorgado por vapp, en caso de no disponer uno contacte a soporte de vapp al correo soporte@vapp.cl</p>
            <CButton
              color="primary"
              class="active mt-3"
            >
              Register Now!
            </CButton>
          </CCard>-->
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
  import cons from '@/const'
  import axios from "axios"

  export default {
    name: 'LoginAdmin',
    
    data () {
      return {
        //expiracionCookie: "8h",
        enviandoSolicitud: false,
        msjSolicitandoCambio: "",

        tipoMensaje: "success",
        showMensaje : false,
        mensajeLogin: "",
        showMensajeCambioPass: false,
        msjCambiarPass: "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
        cambiarPss: false,

        usuario: {
          username:"",
          pass:""
        },

        logueoResp: null,
      }
    },
    beforeMount() {
      this.validarSesion()
    },
    methods: {
      iniciarSesion: function(){
        var url = cons.port+"://" + cons.ipServer + "/log_in_vapp/" + this.usuario.username + "/" + this.usuario.pass
        if(this.usuario.username == null || this.usuario.username == "" || this.usuario.pass == null || this.usuario.pass == ""){
          this.showMensaje = true
          this.mensajeLogin = "Ingrese correctamente sus datos"
          this.tipoMensaje = "danger"

        }else{
          this.showMensaje = false
          
          axios({
              method: "POST", 
              "url": url,
          }).then(result => {
            /*this.logueoResp = result.data

            if(this.usuario.username == this.usuario.pass){
              this.showMensajeCambioPass = true
              this.msjCambiarPass = "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad, ¿quiere hacerlo?"

            } else{
              this.loguearUser()
            }*/
              
          }, error => {
              this.tipoMensaje = "danger"
              this.mensajeLogin = error.response.data
              this.showMensaje = true
          })
          .finally(() => {
          });
          
        }
        
      },
      loguearUser: function(){

        let cookieUsername = this.$cookie.get('userLogginToken')
        var uslog = this.logueoResp

        if(cookieUsername === null || cookieUsername === ""){

          this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });

        } else {

          if(uslog.tokenUsuario != JSON.parse(cookieUsername).tokenUsuario){
            this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });
          }

        }

        this.showMensaje = true
        this.mensajeLogin = "Bienvenido " + this.usuario.username
        this.tipoMensaje = "success"
        
        this.$router.push(this.logueoResp.rutaRedireccion);

      },
      
      /*cambiarContraseña: function(){

        this.enviandoSolicitud = true
        this.msjSolicitandoCambio = "Enviando Solicitud"
        var url = cons.port+"://" + cons.ipServer + "/solicitar_cambio_pass/"
        
        axios({
            method: "GET", 
            "url": url,
            "headers": {
                'Authorization': `${this.logueoResp.tokenUsuario}`
            }
        }).then(result => {

          this.msjSolicitandoCambio = "Solicitud enviada, revise su correo."
          this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

              
          this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
        })
        .finally(() => {
          
        });
      },*/

      validarSesion: function(){
        let cookieUsername = this.$cookie.get('userLogginToken')

        if(cookieUsername !== null ){
          
          this.$router.push(JSON.parse(cookieUsername).rutaRedireccion);
        }    
      }

    }
  }
</script>
